<template>
    <div>
        <data-selector searchable multiple classes="is-medium is-rounded"
            :description="selectedChecklists.length ? 'Only the selected checklists will be included' : 'All checklists will be included'"
            :items="checklists" :value="selectedChecklists" @input="updateChecklists" value-key="id" label-key="name">
            Checklists
        </data-selector>

        <columns>
            <column>
                <table class="table is-fullwidth">
                    <thead>
                        <draggable v-model="columns" tag="tr">
                            <th class="draggable" v-for="column in columns" :key="column.name" scope="col">
                                <div class="is-flex is-justify-content-space-between is-fullwidth">
                                    {{ column.label }}
                                    <span class="pointer">
                                        <icon class="has-text-warning" @click="editColumnName(column)" icon="edit"
                                            type="far" />
                                        <icon class="has-text-danger" @click="removeColumn(column)" icon="times" />
                                    </span>
                                </div>
                            </th>
                        </draggable>
                    </thead>
                </table>
            </column>
        </columns>
    </div>
</template>
<script>
import { checklist as backend } from '@/api'
import Report from '../../../store/models/Report'
import ChecklistMeta from '../../../store/models/reporting/ChecklistMeta'
import concat from 'lodash/concat'
import Draggable from 'vuedraggable'
import { renameColumnName } from '@/modals'
import { mapMutations } from 'vuex'

export default {

    props: {
        selectedChecklists: {
            type: Array,
            default: () => ([])
        }
    },

    components: {
        Draggable
    },

    data: () => ({
        checklists: [],
        columns: [],
    }),

    beforeCreate() {
        backend.loadList({}, ({data}) => {
            this.checklists = data
        }, () => {})
    },

    mounted() {
        this.columns = this.availableColumns
        this.$store.commit('report/updateColumns', this.columns)
    },

    methods: {
        ...mapMutations('report', [
            'updateChecklists'
        ]),
        async removeColumn(column) {
            if (await this.$confirm({
                title: 'Remove Column',
                message: 'Are you sure you want to remove this column?'
            })) {
                const index = this.columns.indexOf(column)
                this.columns.splice(index, 1)
                this.$store.commit('report/updateColumns', this.columns)
            }
        },
        editColumnName(column) {
            renameColumnName(column).then(name => {
                if (name) {
                    const index = this.columns.indexOf(column)
                    let updatable = this.columns[index]
                    updatable.label = name
                    this.columns.splice(index, 1, updatable)
                    this.$store.commit('report/updateColumns', this.columns)
                }
            })
        }
    },

    computed: {
        availableColumns() {
            return concat(
                Report.baseColumns(),
                ChecklistMeta.availableColumns(),
            )
        },
    }

}
</script>
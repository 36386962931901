export default class ChecklistMeta {
    constructor(meta = {}) {
        this.classifications = meta.classifications || []
    }

    static availableColumns() {
        return [
            { name: 'checklist', label: 'Checklist', type: 'relation', attribute: 'checklist.name' },
            { name: 'work_order', label: 'Work Order #', type: 'relation', attribute: 'workOrder.number', prefix: '#' },
            { name: 'start_date', label: 'Started', type: 'column', attribute: 'started_at', parser: 'date' },
            { name: 'completed_date', label: 'Completed', type: 'column', attribute: 'closed_off_at', parser: 'date' },
            { name: 'status', label: 'Status', type: 'column', attribute: 'status' },
            { name: 'user', label: 'User', type: 'relation', attribute: 'user.full_name' },
        ]
    }
}